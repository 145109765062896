<template>
  <div class="public">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'home'
}
</script>
